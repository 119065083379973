<template>
  <div class="agent">
    <div class="pc" v-if="equipment == 'pc'">
      <navig :w="w" :only="only" />
      <img src="~img/ContactUs/25.png" class="pc-bg">
      <img src="~img/ContactUs/26.png" class="pc-logo">
      <main>
        <h3>Arm开发工具中国区域授权代理商信息</h3>
        <div class="content">
          <ul class="box">
            <li class="box-li" v-for="(item,index) in list" :key="item.infoId">
              <img v-if="index > 0" src="~img/ContactUs/21.png" class="bg-img">
              <div class="vertical">
                <h5>{{item.companyName}}</h5>
                <p v-if="item.companyAddress">地址：{{item.companyAddress}}</p>
                <ul>
                  <li v-if="item.contactPerson">
                    <img src="~img/ContactUs/17.png">
                    <!-- http://www.emdoor.com/ -->
                    <span>联系人：{{item.contactPerson}}</span>
                  </li>
                  <li v-if="item.contactEmail">
                    <img src="~img/ContactUs/28.jpg">
                    <span>邮箱：{{item.contactEmail}}</span>
                  </li>
                  <li v-if="item.contactPhone">
                    <img src="~img/ContactUs/18.png">
                    <span>联系电话：{{item.contactPhone}}</span>
                  </li>
                  <li v-if="item.fixedPhone">
                    <img src="~img/ContactUs/19.png">
                    <span>固定电话：{{item.fixedPhone}}</span>
                  </li>
                  <li v-if="item.webAddress">
                    <img src="~img/ContactUs/20.png">
                    <!-- http://www.emdoor.cn/ -->
                    <span>网址：<a :href="'http://'+ item.webAddress" target="_blank">{{item.webAddress}}</a></span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </main>
      <pc-footer :showFooter="showFooter" />
    </div>
    <div class="web" v-if="equipment == 'web'">
      <web-nav />
      <img src="~img/ContactUs/23.png" class="web-bg">
      <main>
        <img src="~img/ContactUs/24.png" class="web-logo">
        <h3>Arm开发工具中国区域授权代理商信息</h3>
        <div class="conternt">
          <ul class="web-box">
            <li class="web-li" v-for="(item,index) in list" :key="item.infoId">
              <img v-if="index > 0" src="~img/ContactUs/21.png" class="web-img">
              <div class="web-vertical">
                <h4>{{item.companyName}}</h4>
                <p v-if="item.companyAddress">地址：{{item.companyAddress}} </p>
                <ul>
                  <li v-if="item.contactPerson">
                    <img src="~img/ContactUs/17.png">
                    <span>联系人：{{item.contactPerson}}</span>
                  </li>
                  <li v-if="item.contactEmail">
                    <img src="~img/ContactUs/28.jpg">
                    <span>邮箱：{{item.contactEmail}}</span>
                  </li>
                  <li v-if="item.contactPhone">
                    <img src="~img/ContactUs/18.png">
                    <span>联系电话：{{item.contactPhone}}</span>
                  </li>
                  <li v-if="item.fixedPhone">
                    <img src="~img/ContactUs/19.png">
                    <span>固定电话：{{item.fixedPhone}}</span>
                  </li>
                  <li v-if="item.webAddress">
                    <img src="~img/ContactUs/20.png">
                    <span>网址：<a :href="'http://'+ item.webAddress" target="_blank">{{item.webAddress}}</a></span>
                  </li>
                </ul>
              </div>

            </li>
          </ul>
        </div>
      </main>
      <up />
      <div class="foot">
        <web-footer />
      </div>
    </div>
  </div>
</template>


<script>
import navig from "@/components/navigation.vue"; // pc 导航
import pcFooter from "@/components/pcFooter.vue"; // pc 底部
import webNav from "@/components/webNav.vue"; // web 导航
import webFooter from "@/assembly/web/webFooter.vue"; // web 底部
import up from "@/components/Backtotop.vue";

export default {
  components: {
    navig,
    pcFooter,
    webNav,
    webFooter,
    up,
  },
  data() {
    return {
      w: "",
      equipment: "",
      only: true,
      showFooter: true,
      list: [],
    };
  },
  created() {
    this.w = document.documentElement.offsetWidth || document.body.offsetWidth;
    if (this.w <= 750) {
      this.equipment = "web";
    } else {
      this.equipment = "pc";
    }
    console.log(this.equipment);
    this.getAgent();
  },
  mounted() {
    document.title = "代理商信息 - 安谋科技";
    window.onresize = () => {
      // 动态检测宽度变化
      return (() => {
        this.w = document.body.clientWidth;
        if (this.w <= 750) {
          this.equipment = "web";
        } else {
          this.equipment = "pc";
        }
      })();
    };
  },
  methods: {
    async getAgent() {
      const res = await this.axios.get("/v1/api/get_agent");
      if (res.code == "00") {
        // console.log(res);
        this.list = res.data;
        this.list.forEach((item) => {
          item.infoList = [];
          item.infoList.push(
            { contactPerson: item.contactPerson },
            { contactEmail: item.contactEmail },
            { contactPhone: item.contactPhone },
            { fixedPhone: item.fixedPhone },
            { webAddress: item.webAddress }
          );
        });
        console.log(this.list);
      }
    },
  },
};
</script>


<style lang="less" scoped>
.agent {
  .pc {
    background: #000;
    position: relative;
    .pc-bg {
      width: 100%;
      position: absolute;
    }
    .pc-logo {
      width: 0.38rem;
      position: absolute;
      right: 0.75rem;
      top: 2.6rem;
    }
    main {
      padding-top: 2.55rem;
      padding-left: 1.38rem;
      position: relative;
      h3 {
        font-size: 0.7rem;
        font-family: Microsoft Microsoft YaHei;
        font-weight: 400;
        color: #e1e6ec;
        line-height: 0.38rem;
      }
      .content {
        width: 16.42rem;
        background: rgba(38, 38, 38, 0.4);
        border-radius: 0.2rem;
        margin-top: 1.03rem;
        margin-bottom: 0.97rem;
        padding: 0rem 0.3rem 0.4rem 0.3rem;
        .box {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          .box-li {
            width: 7.62rem;
            height: 4.3rem;
            margin-top: 0.4rem;
            border-radius: 0.2rem;
            background: #262626;
            position: relative;
            padding-left: 0.6rem;
            padding-right: 0.6rem;
            position: relative;
            overflow: hidden;
            .vertical {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
            }
            .bg-img {
              width: 2.09rem;
              position: absolute;
              right: 0;
              bottom: 0;
            }
            h5 {
              color: #fff;
              font-size: 0.4rem;
            }
            p {
              color: #c6c6c6;
              // width: 5.2rem;
              font-size: 0.22rem;
              margin-top: 0.2rem;
            }
            ul {
              li {
                position: relative;
                img {
                  width: 0.23rem;
                  height: 0.24rem;
                  position: absolute;
                  top: 50%;
                  transform: translate(0, -50%);
                }
                span {
                  color: #c6c6c6;
                  line-height: 0.6rem;
                  padding-left: 0.5rem;
                  font-size: 0.26rem;
                }
                a {
                  color: #c6c6c6;
                }
              }
            }
          }
          .box-li:nth-child(1) {
            background: none;
          }
        }
      }
    }
  }
  .web {
    // background: url("~img/ContactUs/23.png") no-repeat;
    // background-size: contain;
    background: #000;
    position: relative;
    .web-bg {
      width: 100%;
      position: absolute;
    }
    main {
      padding-top: 80px;
      position: relative;
      .web-logo {
        width: 231px;
        float: right;
        margin-right: 25px;
        margin-top: 15px;
      }
      h3 {
        font-size: 28px;
        color: #e1e6ec;
        margin-top: 80px;
        margin-left: 26px;
        margin-right: 41px;
      }
      .conternt {
        margin-left: 26px;
        margin-right: 24px;
        margin-top: 39px;
        padding-bottom: 10px;
        background: rgba(38, 38, 38, 0.4);
        // height: 500px;
        .web-box {
          // padding-top: 25px;
          .web-li {
            width: 91.33%;
            margin: auto;
            margin-bottom: 21px;
            background: #262626;
            position: relative;
            min-height: 136px;
            h4 {
              font-size: 18px;
              color: #fff;
              margin-left: 20px;
            }
            p {
              font-size: 11px;
              color: #c6c6c6;
              margin-left: 20px;
              margin-top: 10px;
            }
            .web-img {
              width: 105px;
              height: 131px;
              position: absolute;
              right: 0;
              bottom: 0;
            }
            .web-vertical {
              position: absolute;
              top: 50%;
              transform: translate(0, -50%);
              padding-right: 20px;
            }
            ul {
              margin-top: 15px;
              li {
                position: relative;
                margin-top: 10px;
                img {
                  width: 13px;
                  position: absolute;
                  top: 50%;
                  transform: translate(0, -50%);
                }
                span {
                  color: #c6c6c6;
                  margin-left: 30px;
                }
                a {
                  color: #c6c6c6;
                }
              }
            }
          }
          .web-li:nth-child(1) {
            background: none;
          }
        }
      }
    }
    .foot {
      position: relative;
      padding-bottom: 10px;
      background-color: #000;
    }
  }
}
</style>